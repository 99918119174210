<template>
  <div class="mt-4">Add New Admin User</div>
  <div class="mt-2 flex gap-4">
    <div>
      <input type="hidden" v-model="person_id" />
      <input
        id="admin_user_email"
        class="text-sm h-6 w-60"
        v-model.trim="email"
        type="text"
        placeholder="Email Address"
      />
    </div>
    <div>
      <input
        id="admin_user_firstname"
        class="text-sm h-6 w-32"
        v-model.trim="firstname"
        type="text"
        placeholder="First Name"
      />
    </div>
    <div>
      <input
        id="admin_user_lastname"
        class="text-sm h-6 w-32"
        v-model.trim="lastname"
        type="text"
        placeholder="Last Name"
      />
    </div>
    <div>
      <input
        id="checkbox_admin"
        class="mr-1 rounded"
        v-model="adm"
        type="checkbox"
      />
      Admin?
    </div>
    <div>
      <input
        id="checkbox_faculty"
        class="mr-1 rounded"
        v-model="fac"
        type="checkbox"
      />
      Faculty Mentor?
    </div>
    <div>
      <input
        id="checkbox_adjunct"
        class="mr-1 rounded"
        v-model="saj"
        type="checkbox"
      />
      Super Adjunct?
    </div>
    <div>
      <input
        id="checkbox_assistant"
        class="mr-1 rounded"
        v-model="sra"
        type="checkbox"
      />
      Research Assistant?
    </div>
    <div class="-mt-1.5">
      <Button data-cy="button-Save" text="Add" @click="saveNewAdmin" />
    </div>
  </div>
  <div class="mt-4">Search Current People (by Email)</div>
  <div class="mt-2">
    <Multiselect
      v-model="label"
      value="null"
      ref="multiselect"
      noOptionsText=""
      :minChars="2"
      :delay="0"
      :searchable="true"
      :classes="classes"
      :options="searchAdmins"
      @select="selectAdmin"
    >
    </Multiselect>
  </div>
</template>

<script>
import { get, post } from "@/composables/httpUtil";
import Button from "@/components/reusable-components/base-level-components/Button";
import Multiselect from "@vueform/multiselect";

export default {
  name: "AddNewAdmin",
  components: { Button, Multiselect },
  emits: ["loadTable"],
  props: {
    currentList: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      person_id: "",
      firstname: "",
      lastname: "",
      email: "",
      adm: false,
      fac: false,
      saj: false,
      sra: false,
      label: "",
      classes: {
        search: "multiselect-search text-sm h-6 w-60",
        dropdown: "multiselect-dropdown",
        singleLabel: "hidden",
        option: "py-1 pl-3",
        options: "pt-1 w-72",
        optionPointed: "bg-light-purple/30",
      },
    };
  },
  methods: {
    selectAdmin: function (value) {
      // Check for presence of email
      let person = this.currentList.find(
        (p) => p.person_id === value.person_id
      );
      if (person) {
        console.log(
          person.firstname + " " + person.lastname + " is already an admin."
        );
      } else {
        this.firstname = value.firstname;
        this.lastname = value.lastname;
        this.email = value.email;
        this.person_id = value.person_id;
      }
    },
    searchAdmins: async function (query) {
      let url = `admin/users/search?email=` + query;
      if (query) {
        return await get(url)
          .then((results) => {
            return results.map((item) => {
              return { value: item, label: item.email };
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    saveNewAdmin: function () {
      let url = `admin/users/`;
      let record = {};
      if (this.person_id) {
        record = {
          person_id: this.person_id,
          adm: this.adm,
          fac: this.fac,
          saj: this.saj,
          sra: this.sra,
        };
      } else {
        record = {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          adm: this.adm,
          fac: this.fac,
          saj: this.saj,
          sra: this.sra,
        };
      }
      if (
        (record.firstname && record.lastname && record.email) ||
        record.person_id
      ) {
        post(url, record)
          .then(() => {
            this.$emit("loadTable");
            this.firstname = "";
            this.lastname = "";
            this.email = "";
            this.adm = false;
            this.fac = false;
            this.saj = false;
            this.sra = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("Missing needed data");
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../node_modules/@vueform/multiselect/themes/tailwind.scss";
</style>
