<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-users">Users</div>
    <div class="box-style overflow-auto">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <div class="mb-2">
          <Button
            data-cy="button-Delete"
            text="Delete"
            @click="deleteAdmin"
            :disabled="!adminToDelete"
            size="small"
          />
        </div>

        <dynamic-table
          :records="listOfAdmins"
          :fields="table_headers"
          @toggleSelected="selectAdmin"
          no-data-msg="No Admin Users to display for the current filter"
          striped
          selectable
          select="ONE"
          idKey="email"
          hover
          pagination
          @pageChanged="nextPage($event)"
          :paginationPage="paginationPage"
          :pagination-total-items="paginationTotalItems"
        >
          <template #cell(email)="{ value }">
            {{ value }}
          </template>
          <template #cell(adm)="{ item }">
            <input
              type="checkbox"
              :data-id="`${item.id}`"
              class="rounded"
              v-model="item.adm"
              @change="togglePerm(item)"
            />
          </template>
          <template #cell(fac)="{ item }">
            <input
              type="checkbox"
              :data-id="`${item.id}`"
              class="rounded"
              v-model="item.fac"
              @change="togglePerm(item)"
            />
          </template>
          <template #cell(saj)="{ item }">
            <input
              type="checkbox"
              :data-id="`${item.id}`"
              class="rounded"
              v-model="item.saj"
              @change="togglePerm(item)"
            />
          </template>
          <template #cell(sra)="{ item }">
            <input
              type="checkbox"
              :data-id="`${item.id}`"
              class="rounded"
              v-model="item.sra"
              @change="togglePerm(item)"
            />
          </template>
        </dynamic-table>
      </div>
      <AddNewAdmin @loadTable="loadTable" :currentList="listOfAdmins" />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { get, put, del } from "@/composables/httpUtil";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import AddNewAdmin from "@/components/displays/admin-displays/AddNewAdmin";
import Button from "@/components/reusable-components/base-level-components/Button";

export default {
  name: "AdminUsers",
  components: { Button, LoadingSpinner, DynamicTable, AddNewAdmin },
  data() {
    return {
      page: 1,
      screenDisplay: "ADMIN_USERS_VIEW",
      listOfAdmins: [],
      isLoading: false,
      adminToDelete: "",

      paginationPage: 1,
      paginationTotalItems: 0,
      paginationTotalPages: 0,
      itemsPerPage: 25,

      table_headers: [
        {
          display: "Email Address",
          name: "email",
          sortable: true,
          sortType: "STRING",
          class: "w-1/4 text-left",
        },
        {
          display: "First Name",
          name: "firstname",
          sortable: true,
          sortType: "STRING",
          class: "w-1/8 text-left",
        },
        {
          display: "Last Name",
          name: "lastname",
          sortable: true,
          sortType: "STRING",
          class: "w-1/8 text-left",
        },
        {
          display: "Admin?",
          name: "adm",
          sortable: false,
          sortType: "",
          class: "w-1/8 text-center",
        },
        {
          display: "Faculty Mentor?",
          name: "fac",
          sortable: false,
          sortType: "",
          class: "w-1/8 text-center",
        },
        {
          display: "Super Adjunct?",
          name: "saj",
          sortable: false,
          sortType: "",
          class: "w-1/8 text-center",
        },
        {
          display: "Research Assistant?",
          name: "sra",
          sortable: false,
          sortType: "",
          class: "w-1/8 text-center",
        },
      ],
    };
  },
  created() {
    this.isLoading = true;
    this.loadTable();
    this.paginationPage = Number(
      Object.keys(this.$route.query).includes("page")
        ? this.$route.query.page
        : this.paginationPage
    );

    this.itemsPerPage = Number(
      Object.keys(this.$route.query).includes("pageSize")
        ? this.$route.query.pageSize
        : this.itemsPerPage
    );
  },
  methods: {
    nextPage(paginationInformation) {
      this.paginationPage = paginationInformation.newPage;
      this.loadTable();
    },
    selectAdmin: function (record) {
      // console.log(record);
      // if checkbox is selected...
      this.adminToDelete = record.id;
    },
    deleteAdmin: function () {
      if (this.adminToDelete) {
        // console.log("Deleting:" + this.adminToDelete);
        let url = `admin/users/` + this.adminToDelete;
        del(url)
          .then(() => {
            this.loadTable();
          })
          .catch((error) => {
            console.log("error (deleteAdmin):", error);
            this.isLoading = false;
          });
      } else {
        console.log("No Admin Selected");
      }
    },
    loadTable: function () {
      let url = `admin/users?page=${this.paginationPage}&limit=${this.itemsPerPage}`;
      get(url)
        .then((results) => {
          this.listOfAdmins = results.data;

          this.paginationTotalItems = results.count;
          this.paginationTotalPages = results.total_pages;
        })
        .catch((error) => {
          console.log("error (loadTable):", error);
        })
        .finally(() => (this.isLoading = false));
    },
    togglePerm: function (record) {
      let url = `admin/users/` + record.id;
      put(url, record)
        .then(() => {})
        .catch((error) => {
          console.log("error (togglePerm):", error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
